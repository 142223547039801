import { useState } from 'react';
import dynamic from 'next/dynamic';
import { useForm } from 'react-hook-form';

import { TextField } from '#atoms/index';
import { Toast } from '#components/index';
import { postContactUsB2b } from '#api/b2b';
import useWindowSize from '#app/hooks/useWindowSize';
import PhoneInput from '#app/UI/molecules/phone-input';
import IconWhatsApp from '#app/UI/atoms/icons/whatsApp';
import Select from '#app/UI/molecules/select';

import styles from './index.module.scss';

const Button = dynamic(() => import('#app/UI/molecules/new-button'));
const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));

const ProgramsForOrganizations = () => {
  const [selectedTypeOrganization, setSelectedTypeOrganization] = useState();
  const [prefix, setPrefix] = useState();
  const { isDesktop } = useWindowSize();
  const [notificationForm, setNotificationForm] = useState({
    isVisible: false,
    message: ''
  });
  const {
 register, handleSubmit, errors, reset, clearErrors 
} = useForm();

  const onSubmit = async data => {
    try {
      if (
        data &&
        selectedTypeOrganization &&
        data.phone &&
        data.email &&
        data.full_name &&
        data.organization_name &&
        prefix
      ) {
        const response = await postContactUsB2b({
          full_name: data.full_name,
          organization_type: selectedTypeOrganization?.name,
          phone: `+${prefix} ${data.phone}`,
          email: data.email,
          organization: data.organization_name
        });
        if (response.status === 200) {
          reset();
          clearErrors();
          setSelectedTypeOrganization(null);
          setNotificationForm({
            isVisible: true,
            message: '¡Gracias por contactarte! Pronto nos comunicaremos contigo'
          });
          setTimeout(() => {
            setNotificationForm({
              isVisible: false,
              message: ''
            });
          }, 4000);
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleSelect = option => {
    setPrefix(option.phone_code);
  };

  const handleClickOrganization = option => {
    setSelectedTypeOrganization(option);
    //setSelectedFrecuencyDays(option.id);
  };

  const objTypeOrganization = [
    { id: 1, label: 'Gobierno', name: 'Gobierno' },
    { id: 2, label: 'Empresa', name: 'Empresa' },
    { id: 3, label: 'Institucion educativa', name: 'Educativa' },
    { id: 4, label: 'Fundación', name: 'Fundación' }
  ];

  return (
    <div className={styles.wrapper} id="programs-for-organizations">
      <div className={styles.header}>
        <Typography className={styles.title} tag="h2">
          Conoce nuestros programas personalizados {isDesktop && <br />}
          <span>para organizaciones</span>
        </Typography>
      </div>

      <section className={styles.body}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.row}>
            <Typography className={styles.label} tag="p">
              Tipo de organización
            </Typography>

            <Select
              size="default"
              onclickDefaultValue={handleClickOrganization}
              options={objTypeOrganization}
              dividerItems
              identifier="label"
              value={selectedTypeOrganization}
              placeholder="Selecciona el tipo de organización"
            />
          </div>
          <div className={styles.row}>
            <Typography className={styles.label} tag="p">
              Nombre y apellido *
            </Typography>

            <TextField
              id="full_name"
              variant="program-organization"
              maxlength="40"
              placeholder="Escribe tu nombre y apellido"
              name="full_name"
              size="20"
              type="text"
              register={register({ required: 'El nombre y apellido son obligatorios' })}
              error={!!errors.full_name}
            />

            {errors.full_name && <Toast message={errors.full_name.message} mtop="1px" />}
          </div>
          <div className={styles.row}>
            <Typography className={styles.label} tag="p">
              Email corporativo *
            </Typography>

            <TextField
              id="email"
              variant="program-organization"
              maxlength="40"
              placeholder="Escribe tu email corporativo"
              name="email"
              size="20"
              type="text"
              register={register({ required: 'El email es obligatorio' })}
              error={!!errors.email}
            />

            {errors.email && <Toast message={errors.email.message} mtop="1px" />}
          </div>
          <div className={styles.rowColumns}>
            <div className={styles.prefixColumn}>
              <Typography className={styles.label} tag="p">
                Prefijo *
              </Typography>
              <PhoneInput
                register={register({ required: 'Selecciona el código' })}
                onSelect={handleSelect}
                name="phone_code"
              />
              {errors.phone_code && (
                <Toast message={errors.phone_code.message} mtop="1px" />
              )}
            </div>
            <div className={styles.phoneColumn}>
              <Typography className={styles.label} tag="p">
                Teléfono *
              </Typography>
              <TextField
                id="phone"
                variant="program-organization"
                maxlength="40"
                placeholder="Número de teléfono"
                name="phone"
                size="20"
                type="text"
                register={register({ required: 'El teléfono es obligatorio' })}
                error={!!errors.phone}
              />
              {errors.phone && <Toast message={errors.phone.message} mtop="1px" />}
            </div>
          </div>
          <div className={styles.row}>
            <Typography className={styles.label} tag="p">
              Nombre de tu organización *
            </Typography>
            <TextField
              id="organization_name"
              variant="program-organization"
              maxlength="40"
              placeholder="Escribe el nombre de tu organización"
              name="organization_name"
              size="20"
              type="text"
              register={register({required: 'El nombre de la organización es obligatorio'})}
              error={!!errors.organization_name}
            />
            {errors.organization_name && (
              <Toast message={errors.organization_name.message} mtop="1px" />
            )}
          </div>
          <div className={styles.row}>
            <Button
              type="submit"
              variant="orange"
              width="100%"
              height="56px"
              textOptions={{
                fontSize: [20, 20],
                lineHeight: [24, 24]
              }}
            >
              Quiero que me contacten
            </Button>
            {notificationForm.isVisible && (
              <Toast message={notificationForm.message} variant="success" mtop="10px" />
            )}
          </div>
        </form>
        <div className={styles.aside}>
          <Button
            type="url"
            target="_blank"
            href={
              'https://api.whatsapp.com/send?phone=17867892469&text=Deseo%20recibir%20informaci%C3%B3n%20sobre%20programas%20personalizados%20para%20organizaciones'
            }
            variant="aqua"
            width="100%"
            height="54px"
            iconLeft={<IconWhatsApp fill="#171A1A" />}
            textOptions={{
              fontSize: [20, 20],
              color: '#171A1A'
            }}
          >
            Contáctanos vía Whatsapp
          </Button>
          <img
            className={styles.image}
            src="./home/programs-for-organizations/programs-organizations.webp"
            alt=""
          />
        </div>
      </section>
    </div>
  );
};

export default ProgramsForOrganizations;
