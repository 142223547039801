import cx from 'classnames';
import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';

import IconArrowUp from '#app/UI/atoms/icons/iconArrowUp';
import IconArrowDown from '#app/UI/atoms/icons/iconArrowDown';

import styles from './index.module.scss';
import SelectOptions from '../select-options';
import ItemFloatingMenu from '../item-floating-menu';

const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));

const Select = ({
  children,
  isOpen = false,
  setIsOpen,
  size = 'default',
  className = '',
  disabled,
  options,
  value,
  width = 'auto',
  onclickDefaultValue,
  dividerItems = false,
  identifier = 'name',
  itemsDropdown = 8,
  placeholder = 'Seleccionar',
  sort = false
}) => {
  const ref = useRef(null);
  const [isOpenSelect, setIsOpenSelect] = useState(isOpen);
  const [itemSelected, setItemSelected] = useState(value);
  const dataSort = Array.isArray(options) ? [...options] : options;

  useEffect(() => {
    setItemSelected(value);
  }, [value]);

  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpenSelect(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const onClickHandler = option => {
    if (option) {
      onclickDefaultValue && onclickDefaultValue(option);
      setIsOpenSelect(!isOpenSelect);
      setItemSelected(option);
    }
  };

  return (
    <>
      <div
        style={{ width: width }}
        className={cx(className, styles.container, {[styles.container__disabled]: disabled})}
        //className={`select-comp ${disabled ? 'select-comp__disabled' : ''} ${className}`}
        onClick={disabled ? () => {} : () => setIsOpenSelect(!isOpenSelect)}
      >
        <div className={styles.text}>
          <Typography
            tag="p"
            className={cx(
              styles.ellipsis,
              styles.placeholder,
              disabled
                ? styles.textDisabled
                : !itemSelected?.[identifier] && placeholder
                ? styles.textGris
                : styles.textBlack
            )}
          >
            {itemSelected?.[identifier] || placeholder}
          </Typography>
        </div>
        {disabled ? (
          <div
            className={cx(
              styles.icon,
              { [styles[`icon__${size}`]]: size },
              { [styles.icon__disabled]: disabled }
            )}
            /* className={`select-comp__icon select-comp__icon--${size} ${
              disabled ? 'select-comp__icon--disabled' : ''
            }`} */
          >
            <IconArrowDown fill="#BCBCBC" />
          </div>
        ) : (
          <div
            /* onClick={() => setIsOpenSelect(!isOpen)} */
            className={cx(styles.icon, { [styles[`icon__${size}`]]: size })}
            /* className={`select-comp__icon select-comp__icon--${size}`} */
          >
            {isOpenSelect ? <IconArrowUp /> : <IconArrowDown />}
          </div>
        )}
        {isOpenSelect && (
          <div ref={ref} className={styles.dropdown}>
            <SelectOptions
              isScroll={dataSort && dataSort.length > itemsDropdown ? true : false}
              size={size}
              itemsDropdown={itemsDropdown}
              options={
                sort
                  ? dataSort
                      ?.sort((a, b) => (a?.[identifier] > b?.[identifier] ? 1 : -1))
                      .map(option => (
                        <ItemFloatingMenu
                          dividerItems={dividerItems}
                          key={option?.id}
                          disabled={option?.isDisabled}
                          onClick={() => onClickHandler(option)}
                        >
                          {option?.[identifier]}
                        </ItemFloatingMenu>
                      ))
                  : dataSort?.map(option => (
                      <ItemFloatingMenu
                        dividerItems={dividerItems}
                        key={option?.id}
                        disabled={option?.isDisabled}
                        onClick={() => onClickHandler(option)}
                      >
                        {option?.[identifier]}
                      </ItemFloatingMenu>
                    ))
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Select;
