import cx from 'classnames';

import styles from './index.module.scss';

const ItemFloatingMenu = ({
  children,
  onClick,
  disabled = false,
  dividerItems = false,
  dataTestid = 'Item-floating-menu-test-id',
  color = '#555555'
}) => {
  return (
    <li
      data-testid={dataTestid}
      className={cx(
        styles.container,
        disabled ? styles.container__disabled : styles.container__active
      )}
      /* `item-floating-menu item-floating-menu--${
        disabled ? 'disabled' : 'active'
      }`
			 */
      onClick={disabled ? () => {} : onClick}
      style={{
        borderBottom: dividerItems ? `1px solid #e5e5e5` : 'none',
        color: color
      }}
    >
      {children}
    </li>
  );
};

export default ItemFloatingMenu;
