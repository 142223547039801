const IconArrowDown = ({ fill = 'black', className, dataTestid = 'icon-arrow-down' }) => (
  <svg
    data-testid={dataTestid}
    className={className}
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={`${className}-path`}
      data-testid={`${dataTestid}-path`}
      d="M10.59 0.590088L6 5.17009L1.41 0.590088L0 2.00009L6 8.00009L12 2.00009L10.59 0.590088Z"
      fill={fill}
    />
  </svg>
);

export default IconArrowDown;
