import cx from 'classnames';

import styles from './index.module.scss';

const SelectOptions = ({
  options,
  size,
  isScroll,
  addIsScroll = 'isScroll',
  className,
  itemsDropdown = 8,
  dataTestid = 'select-options-test-id',
  noOptionsText = 'Se encontraron 0 coincidencias'
}) => {
  return (
    <>
      <div
        data-testid={dataTestid}
        className={cx(styles.container, styles.modal, {[styles[`modal__${size}`]]: size})}

        /* className={`select-options__modal select-options__modal--${size} ${
          isScroll
            ? `select-options__modal--${addIsScroll} select-options__modal-qty-items${itemsDropdown}`
            : ''
        }  ${className ? className : ''}`} */
      >
        <>
          {options && options.length > 0 ? (
            <ul className={styles.modalContent}>{options?.map(option => option)}</ul>
          ) : (
            <ul className={styles.noContent}>{noOptionsText}</ul>
          )}
        </>
      </div>
    </>
  );
};

export default SelectOptions;
