import cx from 'classnames';
import {
 useState, useRef, useEffect, useContext 
} from 'react';

import { countries } from '#app/helpers/countries';
import GlobalContext from '#context/Global/GlobalContext';
import IconArrowDown from '#app/UI/atoms/icons/arrowDown';

import styles from './index.module.scss';

const PhoneInput = ({ onSelect, register, name }) => {
  const { geolocalization_data } = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const selectRef = useRef(null);

  useEffect(() => {
    if (geolocalization_data && geolocalization_data.countryCode) {
      const defaultCountry = countries.find(
        country => country.iso2 === geolocalization_data?.countryCode?.toUpperCase()
      );
      if (defaultCountry) {
        setSelectedOption(defaultCountry);
        onSelect(defaultCountry);
      }
    }
  }, [geolocalization_data.countryCode]);
  useEffect(() => {
    // this function is called when a click is detected on the document
    const handleClickOutside = event => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false); // close the select if the click was outside the component
      }
    };

    // add the event listener to the document
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // clear event to avoid memory leaks
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectCountry = option => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect(option);
  };

  return (
    <div className={styles.customSelect} ref={selectRef}>
      <div className={styles.selectedOption} onClick={() => setIsOpen(!isOpen)}>
        {selectedOption ? (
          <>
            <img src={`/countries/svg/${selectedOption.iso2.toLowerCase()}.svg`} alt="" />
            <input
              id={name}
              data-testid="phone-code"
              className={styles.phoneCode}
              name={name}
              ref={register}
              readOnly
              disabled
              value={selectedOption.phone_code ? `+${selectedOption.phone_code}` : ''}
            />
          </>
        ) : (
          'Seleccionar'
        )}
        <IconArrowDown
          className={cx(styles.arrow, { [styles.arrow__isOpen]: isOpen })}
          fill="#555555"
        />
      </div>
      {isOpen && (
        <div className={styles.optionsContainer}>
          {countries.map((option, index) => (
            <div
              key={index}
              className={styles.optionItem}
              onClick={() => handleSelectCountry(option)}
            >
              <img
                src={`/countries/svg/${option.iso2.toLowerCase()}.svg`}
                alt={option.iso2}
              />
              <span>+{option.phone_code}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PhoneInput;
