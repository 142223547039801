import axios from 'axios';

import { baseUrlB2b } from './baseUrl';

export const getDashboardB2b = async organizationId => {
  const response = await axios.get(`${baseUrlB2b}/project/dashboard/${organizationId}`, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
  return response;
};

export const postContactUsB2b = async data => {
  const response = await axios.post(`${baseUrlB2b}/contact/form`, data, {headers: { Authorization: `Bearer ${window.localStorage.getItem('refreshToken')}` }});
  return response;
};
